import React from 'react';
import { Link } from 'gatsby';

import Layout from '../../components/layout';
import SEO from '../../components/seo';

const Background = () => {
  const title = 'Project background';

  return (
    <Layout>
      <SEO title={title} />
      <h1>{title}</h1>

      <p>Dear Sir/Madam,</p>
      <p>
        I write to seek the participation of your school in a project to promote
        the extensive development in two areas: the English language and the
        martial art of Wing Chun.
      </p>
      <p>
        Several months ago, I took part in the "LOVE IDEAS, LOVE HK" programme
        initiated by the Li Ka Shing Foundation. Unfortunately my project was
        rejected in the initial screening for eligibility before the public
        voting, perhaps because the selection board viewed that it is a
        profit-making project (actually it is not) and that I have emigrated
        overseas (actually I have recently returned to Hong Kong). As a result I
        have failed to obtain any funding for my project.
      </p>
      <p>
        The fact is I have retired, and very much wish to serve the community
        with all my heart for the rest of my life, to pay back for what I have
        benefited from it. In view of the above, I am willing to provide to your
        school totally free of charge the service laid out in the submission
        schedule below, given the project is properly supported with publicity
        and training venues provided by your school. In short, my project
        involves myself teaching Wing Chun in English to classes of students for
        50 hours for free, on condition that each student promises to teach Wing
        Chun in English to three new students for 50 hours for free. Then the
        three new students will follow suit and pass down the teaching of Wing
        Chun like a family tree.
      </p>
      <p>
        You may still suspect my motivation for making a lot of effort and
        spending money (on miscellaneous expenses) on this project, which needs
        some explanation.
      </p>
      <p>
        As I may have suggested, I am retired with enough time and money for my
        own disposal and accordingly have no intention of making money. On the
        other hand, I have good knowledge and a lot of passion for teaching both
        English and Wing Chun, and a strong desire to promote extensive
        development in these two areas. Moreover, even at my age (63) I am still
        full of energy and feel young at heart, always committed to be of some
        use to the public.
      </p>
      <p>
        With your kind participation in this project, the lingering problems of
        deteriorating health and unsatisfactory English standard of people in
        Hong Kong can potentially change for the better in the foreseeable
        future.
      </p>
      <p>
        If you are interested in the following project, please email your
        contact details to me so that we may discuss plans of carrying out the
        project.
      </p>
      <p>
        Best regards,
        <br />
        Roland Kong
      </p>

      <hr />

      <h2>Schedule</h2>
      <p>
        First of all, you may have a look at the videos below that illustrate my
        teaching of Wing Chun in English, to better understand my "Learn Wing
        Chun, Learn English" project (the students are supposed to watch the
        video for each one-hour lesson before and after the actual lesson, to
        consolidate what they have learnt and to enable themselves to teach
        their own students more easily):
      </p>
      <p>
        <Link to="/learn/lessons">View video lessons</Link>
      </p>

      <hr />

      <p>
        The following are the details of the project (as originally submitted to
        the "LOVE IDEAS, LOVE HK" selection board):
      </p>

      <h2>Funding Category</h2>
      <p>$25000 or below – non-student individual applicant</p>

      <h2>Name of Project</h2>
      <p>Learn Wing Chun, Learn English</p>

      <h2>Target</h2>
      <p>
        To enable any Hong Kong citizen the chance to develop good health and
        self-defence, and to learn an international language
      </p>

      <h2>Details of the Project</h2>
      <p>
        Following the great interest in Wing Chun with the introduction of quite
        a number of "Ip Man" movies, Hong Kong people’s craving for learning
        Wing Chun has dramatically increased.
      </p>
      <p>
        I have learnt Wing Chun for many years from Master Leung Sheung, the
        first student of Grandmaster Ip Man in Hong Kong, and have been the
        Chairman of Leung Sheung Wing Chun Association. Further, I have been
        teaching Wing Chun part-time continuously all these years.
      </p>
      <p>
        On the other hand, I graduated respectively from Wah Yan College
        (Kowloon), Hong Kong University, Chinese University of Hong Kong and
        Macquarie University in Australia (where I had my Master Degree in
        Applied Linguistics), and over decades I have been a secondary English
        teacher in Hong Kong and have since lived in Australia for 17 years
        shortly before now. As a result I pride myself on my English
        proficiency. So began my idea for the project.
      </p>
      <p>
        According to the project, I first teach Wing Chun in English to classes
        of students for 50 hours for free, over a span of about six months, on
        condition that each student would promise to teach Wing Chun in English
        to three new students for 50 hours for free. Then the three new students
        would follow suit and pass down the teaching of Wing Chun like a family
        tree. The number of students learning Wing Chun (and English) will grow
        rapidly within a short time.
      </p>
      <p>
        Each student is required to undertake the duty of learning and teaching
        Wing Chun, in the form of a pledge. To publicise the project and to help
        students develop a sense of belonging, a website will be established,
        with a family-tree-like structure showing the students their appropriate
        positions in this big family of Wing Chun. Moreover, a communication
        network will be built up to facilitate interactive activities among
        students, thus enhancing the improvement of English and Wing Chun
        skills.
      </p>
      <p>
        In addition, workshops will be regularly organised and videos for each
        Wing Chun lesson can be accessed from the Website after being uploaded
        onto YouTube, enabling the students ample opportunities for continuous
        education and enrichment.
      </p>
      <p>
        Given the success of the project, the lingering problems of
        deteriorating health and unsatisfactory English standard of Hong Kong
        people will substantially change for the better in the foreseeable
        future.
      </p>
    </Layout>
  );
};

export default Background;
